import React from 'react'

function Footer() {
    return (
        <footer className="main_footer">
            <div className="footer_area">
                <div className="container">
                    <div className="footer">
                        <h3>GET IN TOUCH WITH US</h3>
                        <ul>
                            <li>
                                <a href="https://twitter.com/bugscoin_" target="_blank">
                                    <img src="assets/images/social/1.png" alt=""/>
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/bugscoin" target="_blank">
                                    <img src="assets/images/social/2.png" alt=""/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/bugsbunnycoin.io/" targeg="_blank">
                                    <img src="assets/images/social/3.png" alt=""/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@bugsbunnycoin_?_t=8fuU4PAgDRa&_r=1" target="_blank">
                                    <img src="assets/images/social/4.png" alt=""/>
                                </a>
                            </li>
                            <li>
                                <a href="https://youtube.com/@BugsBunnyCoin?feature=shared" target="_blank">
                                    <img src="assets/images/social/5.png" alt="" />
                                </a>
                            </li>
                        </ul>
                        <a href="mailto:contact@bugsbunnycoin.io" className="mail">
                            contact@bugsbunnycoin.io
                        </a>
                        <p>
                            Cryptocurrency may be unrequlated in your jurisdiction. The value of
                            cryptocurrencies may go down as well as up. Profits may be subject to
                            capital gains or other taxes applicable in your jurisdiction.
                        </p>
                        <br />
                
                        <p align="center"><small>bugsbunnycoin.io is in no way affiliated with the original Bugs Bunny creator or the Warner Bros. Cartoons, Inc.</small></p>
                    </div>
                </div>
                <img src="assets/images/social/Pattern.png" alt="" className="pattern_1" />
                <img src="assets/images/social/Pattern2.png" alt="" className="pattern_2" />
            </div>
            <div className="copy_right_area">
                <div className="container">
                    <div className="copy_text">
                        <p>Copyright © 2023 Bugs Bunny Coin- All rights reserved</p>
                        <ul>
                            <li>
                                <a href="assets/images/Terms_and_Conditions_$BUGS.pdf" target="_blank">
                                    Terms &amp; Conditions
                                </a>
                            </li>
                            <li>
                                <a href="assets/images/Privacy_Policy_$BUGS.pdf" target="_blank">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="assets/images/Cookies_$BUGS.pdf" target="_blank">
                                    Cookies
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer