import React from "react";
import { useWeb3Modal } from "@web3modal/react";
import { getAccount } from "@wagmi/core";

export default function Header() {
  const { open } = useWeb3Modal();
  const { address } = getAccount();

  return (
    <header className="header_area_main">
      <div className="container">
        <div className="header_box">
          <div className="logo">
            <a href="#">
              <img src="assets/images/logo.png" alt="" />
              <span>Bugs Bunny</span>
            </a>
            <a className="bars_menu" href="#">
              <i className="fas fa-bars" />
            </a>
          </div>
          <div className="menu">
            <ul>
              <li style={{ color: "#ffffff", fontWeight: "600", cursor: "pointer" }}>

                Home

              </li>
              <li>
                <a href="#about">About</a>
              </li>
              {/* <li>
                <a href="#how_to_buy">How to Buy</a>
              </li> */}
              <li>
                <a href="#roadmap">Roadmap</a>
              </li>
              {/* <li>
                <a href="#tokenomics">Tokenomics</a>
              </li> */}
              <li>
                <a href="#faq">FAQ</a>
              </li>
            </ul>
            <div onClick={async () => {
              await open();
            }} className="menu_btn">
              <a className="btn_1 desktop_hide">
                {address
                  ? address.slice(0, 7) + "..."
                  : "Connect Wallet"}
              </a>
            </div>
          </div>
          <div onClick={async () => {
            await open();
          }} className="header_btn mobile_hide">
            <a className="btn_1">
              {address
                ? address.slice(0, 7) + "..."
                : "Connect Wallet"}
            </a>
          </div>
        </div>
      </div>
    </header>

  );
}
