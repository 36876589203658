import React, { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { getAccount } from "@wagmi/core";
import { Box, useMediaQuery } from "@mui/material";
import { preSaleReadFunction, preSaleWriteFunction, tokenReadFunction, usdtWriteFunction } from "../ConnectivityAssets/hooks";
import { ToastNotify } from "../components/SmallComponents/AppComponents";
import Loading from "../components/SmallComponents/loading";
import { formatUnits, parseUnits } from "viem";
import { presaleAddress } from "../ConnectivityAssets/environment";
import moment from 'moment'
import TimerCountDown from "../components/SmallComponents/PresaleTimer";

function HeroSection() {
  const { open } = useWeb3Modal();
  const { address } = getAccount();
  const [token, setToken] = useState("USDT");
  const [enteredAmountETH, setEnteredAmountETH] = useState("");
  const [enteredAmountUSDT, setEnteredAmountUSDT] = useState("");
  const [endTime, setendTime] = useState(1674475330);
  const [callFunction, setCallFunction] = useState(true);
  const [receivedTokensETH, setreceivedTokensETH] = useState("");
  const [receivedTokensUSDT, setreceivedTokensUSDT] = useState("");
  const [tokenPerUSDT, settokenPerUSDT] = useState(0);
  const [tokenPerETH, settokenPerETH] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [currentStage, setcurrentStage] = useState(0);
  const [loading, setloading] = useState(false);
  const [amountRaisedForAll, setamountRaisedForAll] = useState(0);
  const [progressBarForAll, setprogressBarForAll] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const initVoidSigner = async () => {
    try {
      let dec = await tokenReadFunction("decimals");
      console.log(+dec.toString());
      let stage = await preSaleReadFunction("presaleId");
      console.log(+stage.toString());
      setcurrentStage(+stage.toString())
      let usdtToToken = await preSaleReadFunction("usdtToTokens", [stage.toString(), "1000000"]);
      console.log(+formatUnits(usdtToToken.toString(), +dec.toString()));
      settokenPerUSDT(+formatUnits(usdtToToken.toString(), +dec.toString()))
      let ethToToken = await preSaleReadFunction("ethToTokens", [stage.toString(), "1000000000000000000"]);
      console.log(+formatUnits(ethToToken.toString(), +dec.toString()));
      settokenPerETH(+formatUnits(ethToToken.toString(), +dec.toString()))
      let presaleData = await preSaleReadFunction("presale", [+stage.toString()]);
      setendTime(+presaleData[1].toString())
      settokenPrice(+formatUnits(presaleData[2].toString(), +dec.toString()))
      let arfa = 0
      for (let index = 1; index <= +stage.toString(); index++) {
        let presaleData = await preSaleReadFunction("presale", [+index]);
        arfa += +parseFloat(+formatUnits(presaleData[6].toString(), 6)).toFixed(0)
      }
      setamountRaisedForAll(toLocalFormat(+arfa))
      let progForAll = (+arfa / 490000) * 100
      setprogressBarForAll(+progForAll)
      setCallFunction(false)
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner()
  }, [callFunction]);
  useEffect(() => {
    if (address) {
      (async () => {
        let dec = await tokenReadFunction("decimals");
        let userData = await preSaleReadFunction("userClaimData", [address]);
        setuserPurchasedTokens(+formatUnits(userData[1].toString(), +dec))
      })()
    }
  }, [address]);
  const buyHadler = async () => {
    if (address) {
      if (!enteredAmountETH && token === "ETH") {
        setAlertState({
          open: true,
          message: `Error! Please enter an amount`,
          severity: "error",
        });
      } else if (!enteredAmountUSDT && token === "USDT") {
        setAlertState({
          open: true,
          message: `Error! Please enter an amount`,
          severity: "error",
        });
      } else {
        try {
          setloading(true)
          if (token === "USDT") {
            console.log("CALLED USDT");
            await usdtWriteFunction("approve", [
              presaleAddress,
              parseUnits(enteredAmountUSDT.toString(), 6).toString(),
            ]);
            await preSaleWriteFunction("buyWithUSDT", [
              parseUnits(enteredAmountUSDT.toString(), 6).toString(),
            ]);
          } else {
            console.log("CALLED ETH");
            await preSaleWriteFunction(
              "buyWithEth",
              [],
              parseUnits(enteredAmountETH.toString(), 18).toString()
            );
          }
          setAlertState({
            open: true,
            message: "Success! Transaction Confirmed",
            severity: "success",
          });
          setEnteredAmountETH("");
          setreceivedTokensUSDT(0)
          setreceivedTokensETH(0)
          setCallFunction(true);
          setloading(false);
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  const calculatorETH = async (e) => {
    try {
      setToken("ETH")
      let v = +e.target.value
      setEnteredAmountETH(e.target.value)
      let tokenEth = +tokenPerETH * v;
      setreceivedTokensETH(tokenEth);
    } catch (error) { }
  };
  const calculatorUSDT = async (e) => {
    try {
      setToken("USDT")
      let v = +e.target.value
      setEnteredAmountUSDT(e.target.value)
      let tokenUSDT = +tokenPerUSDT * v;
      setreceivedTokensUSDT(tokenUSDT);
    } catch (error) { }
  };
  const claimHandler = async () => {
    if (address) {
      try {
        setloading(true);
        await preSaleWriteFunction("claimAmount");
        setloading(false);
        setAlertState({
          open: true,
          message: `Transection Completed!`,
          severity: "success",
        });
      } catch (error) {
        setloading(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  return (
    <section className="banner_area" id="header_areaa">
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-to-hide-on-mobile">
            <div className="banner_text">
            <h2> lots of $BUGS will turn to lots of BUCKS!</h2>
            <p>
                Are you ready to hop down the crypto rabbit hole with Bugs Bunny
                ($BUGS)? Our cryptocurrency is not just another token; it's a
                vibrant and innovative community-driven project designed to bring
                joy and financial freedom to our holders.
              </p>
              {/* <button class="btn_1">TAKE A DIVE</button> */}
              <div className="bammer_btnn">
                <a href="assets/images/Whitepaper_$BUGS.pdf" target="_blank" className="btn_1">
                  DIG IN
                </a>
                <a href="https://app.uniswap.org/swap?inputCurrency=0x490bd60a5d3e1207fba9b699017561434cc8c675&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" target="_blank" className="btn_2">
                  BUY NOW
                </a>
              </div>
              <div className="banner_banana">
                <img src="assets/images/banner/cat.png" alt="" class="cat-image" />
              </div>
            </div>
          </div>

          
          <div className="col-lg-6">
            <div className="banner_card">
              <div className="monkey_card">
                {/* <div className="monkey_countdown">
                  <div id="countdown">
                    <TimerCountDown time={+endTime} />

                  </div>
                </div> */}
                {/* <div className="monkey_progress">
                  <div className="progress">
                    <div className="bar" style={{ width: `${+progressBarForAll}%` }}>
                      <p class="percent">Until Next Price $0.01</p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="monekey_info">
                  <p><span className="raised">USDT raised: ${amountRaisedForAll}/$490000</span></p>
                  <p>LISTING PRICE: $0.01</p>
                </div> */}
                {/* <div className="monkey_form_title">
                  <span />
                  <p>1 Bugs Bunny = ${+tokenPrice > 0 ? parseFloat(1 / +tokenPrice).toFixed(3) : "0.00"}</p>
                  <span />
                </div> */}
                <div className="monekey_tabs">
                  {/* <ul className="monekey_button">
                    <li>
                      <button className="active" id="ETH">
                        <img src="assets/images/1.svg" alt="" />
                        <p>ETH</p>
                      </button>
                    </li>
                    <li>
                      <button id="USDT">
                        <img src="assets/images/2.svg" alt="" />
                        <p>USDT</p>
                      </button>
                    </li>
                  </ul> */}
                  {console.log(token)}
                  <div className="monkey_tabs_content">
                    <div className="monkey_form ETH">
                      {/* <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in ETH you pay</label>
                          <input value={enteredAmountETH} onChange={(e) => calculatorETH(e)} type="number" defaultValue={0} />
                        </div>
                        <div className="img_monkey">
                          <img src="assets/images/1.svg" alt="" />
                        </div>
                      </div> */}
                      {/* <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in BUGS you recieve</label>
                          <input readOnly value={receivedTokensETH} type="text" defaultValue={0} />
                        </div>
                        <div className="img_monkey">
                          <img src="assets/images/logo.png" alt="" />
                        </div>
                      </div> */}
                      {address ? <div onClick={() => buyHadler()} className="form_submit_btn">
                        {/* <button>Buy Now</button> */}
                      </div> : <div onClick={async () => {
                        await open();
                      }} className="form_submit_btn">
                        <button>CONNECT WALLET</button>
                      </div>}
                      <Box mt={1} />

                      {/* CLAIM BUTTON START HERE */}


                     {userPurchasedTokens > 0 &&
                        <div onClick={() => claimHandler()
                        } className="form_submit_btn">
                          <button>CLAIM BUGS</button>
                        </div>
                      }

                      {/* Claim Button Ends Here */}

                      <div style={{ textAlign: 'center' }}>
                      <p>Contract Address:</p>
                      <span className="hide-on-desktop" style={{ color: 'white', fontSize: '12px' }}>0x490Bd60a5d3E1207fbA9b699017561434Cc8C675</span>
                      <span className="col-to-hide-on-mobile" style={{ color: 'white', fontSize: '18px' }}>0x490Bd60a5d3E1207fbA9b699017561434Cc8C675</span>
                      </div>
                      
                    </div>
                    <div className="monkey_form USDT">
                      <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in USDT you pay</label>
                          <input value={enteredAmountUSDT} onChange={(e) => calculatorUSDT(e)} type="number" defaultValue={0} />
                        </div>
                        <div className="img_monkey">
                        <img src="assets/images/22.svg" alt="" /></div>
                      </div>
                      <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in BUGS you recieve</label>
                          <input readOnly value={receivedTokensUSDT} type="text" defaultValue={0} />
                        </div>
                        <div className="img_monkey">
                          <img src="assets/images/logo.png" alt="" />
                        </div>
                      </div>
                      {address ? <div onClick={() => buyHadler()} className="form_submit_btn">
                        {/* <button>Buy Now</button> */}
                      </div> : <div onClick={async () => {
                        await open();
                      }} className="form_submit_btn">
                        <button>CONNECT WALLET</button>
                      </div>}
                      <Box mt={1} />


                      {/* Claim Button 2 Starts Here */}

                     {userPurchasedTokens > 0 &&
                        <div onClick={() => claimHandler()
                        } className="form_submit_btn">
                          <button>CLAIM BUGS</button>
                        </div>
                      }

                        {/* Claim Button 2 Ends Here */}

                        
                    </div>
                    <div className="monekey_info">
                      <p style={{ fontSize: "17px" }}>Your Bugs: {userPurchasedTokens}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="col-lg-6 hide-on-desktop">
            <div className="banner_text">
            <h2> lots of $BUGS will turn to lots of BUCKS!</h2>
            <p>
                Are you ready to hop down the crypto rabbit hole with Bugs Bunny
                ($BUGS)? Our cryptocurrency is not just another token; it's a
                vibrant and innovative community-driven project designed to bring
                joy and financial freedom to our holders.
              </p>
              {/* <button class="btn_1">TAKE A DIVE</button> */}
              <div className="bammer_btnn">
                <a href="assets/images/Whitepaper_$BUGS.pdf" target="_blank" className="btn_1">
                  DIG IN
                </a>
                <a href="https://info.uniswap.org/#/pools/0x402b2d2fc1634e2faecf3287541a160617bf4506" className="btn_2">
                  BUY NOW
                </a>
              </div>
              <div className="banner_banana">
                <img src="assets/images/banner/cat.png" alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  );
}

export default HeroSection;
