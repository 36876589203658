import React from 'react'

function Partners() {
    return (
        <section className="featear_area section_padding">
            <div className="container">
                <div className="section_title">
                    <p>partners</p>
                    <h2>FEATURED IN</h2>
                </div>
                <div className="featuress">
                    <div className="feather_logo">
                        <img src="assets/images/feature/1.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/2.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/3.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/4.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/5.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/6.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/7.png" alt="" />
                    </div>
                    <div className="feather_logo"><a href="https://coinmarketcap.com/currencies/bugs-bunny-coin/">
                        <img src="assets/images/feature/8.png" alt="" /></a>
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/9.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/10.png" alt="" />
                    </div>
                    <div className="feather_logo">
                        <img src="assets/images/feature/11.png" alt="" />
                    </div>
                    <div className="feather_logo"><a href="https://www.geckoterminal.com/eth/pools/0x402b2d2fc1634e2faecf3287541a160617bf4506">
                        <img src="assets/images/feature/12.png" alt="" /></a>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Partners