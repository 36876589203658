import React from 'react'

function FAQs() {
    return (
        <section className="question_area section_padding" id="faq">
            <div className="container">
                <div className="section_title step_title">
                    <h4>FAQ</h4>
                    <h2>FREQUENTLY ASKED QUESTIONS</h2>
                    <p>
                        Here, we've compiled answers to some of the most common questions about
                        our token and its ecosystem. If you have a question that isn't addressed
                        here, feel free to reach out to our community or support team for
                        further assistance.
                    </p>
                </div>
                <div className="question_acordion faq" id="faq">
                    <div className="faq-list">
                        <ul>
                            <li>
                                <a
                                    data-bs-toggle="collapse"
                                    className="collapse"
                                    data-bs-target="#faq-list-1"
                                >
                                    Who the hell is Bugs Bunny?{" "}
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-1"
                                    className="collapse show"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        You should know him! But in case you don't: Bugs is an
                                        anthropomorphic gray and white rabbit or hare who is
                                        characterized by his flippant, insouciant personality. He is
                                        also characterized by a Brooklyn accent, his portrayal as a
                                        trickster and his catchphrase "Eh...What's up, doc?".
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li>
                            <li>
                                <a
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-list-2"
                                    className="collapsed"
                                >
                                    How do I buy?
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-2"
                                    className="collapse"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        You are able to buy $BUGS via the DEX Uniswap
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li>
                            <li>
                                <a
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-list-3"
                                    className="collapsed"
                                >
                                    But why $BUGS?
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-3"
                                    className="collapse"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        $BUGS is all about the community! The team plans to engage as
                                        much as possible in new ideas and implementations to expand the
                                        project in all directions. By holding $BUGS you embark on an
                                        exciting and adventurous journey with goals and achievements.
                                        Together we will conquer the Memecoin World!
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li>
                            <li>
                                <a
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-list-4"
                                    className="collapsed"
                                >
                                    How many bunnies will I be able to buy?{" "}
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-4"
                                    className="collapse"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        Why on gods green earth did you click on this question? Anyways:
                                        $BUGS, thanks to the precisely calculated tokenomics and
                                        experienced research of the crypto industry aims to make as much
                                        gains as possible! Especially presale investors will benefit
                                        from the discount! $BUGS may be the next PEPE or DOGE, so do not
                                        miss this opportunity! Therefore we suggest you to build a zoo.
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li>
                            {/* <li>
                                <a
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-list-5"
                                    className="collapsed"
                                >
                                    When can I trade $BUGS?{" "}
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-5"
                                    className="collapse"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        As soon as the last presale phase ends you will be able to trade
                                        $BUGS on Uniswap. We plan to list $BUGS on different exchanges
                                        in the future.
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li> */}
                            <li>
                                <a
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-list-6"
                                    className="collapsed"
                                >
                                    How can I stay up-to-date?{" "}
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-6"
                                    className="collapse"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        On Twitter, Telegram and other social medias, we will post
                                        everything you need to know as soon as possible!
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li>
                            <li>
                                <a
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-list-7"
                                    className="collapsed"
                                >
                                    Doc? Where do I find help?{" "}
                                    <i
                                        className="fa fa-sm fa-angle-down icon-show"
                                        aria-hidden="true"
                                    />
                                    <i className="fa fa-sm fa-angle-up icon-close" />
                                </a>
                                <div
                                    id="faq-list-7"
                                    className="collapse"
                                    data-bs-parent=".faq-list"
                                >
                                    <p>
                                        In order to receive costumer support please send an e-mail
                                        regarding your issues to this E-Mail address:
                                        support@bugsbunnycoin.io - Otherwise you can ask for help in our
                                        official telegram community group. There we are available 24/7!
                                    </p>
                                </div>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default FAQs