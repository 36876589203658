import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import Partners from "./pages/Partners";
import AboutUs from "./pages/AboutUs";
// import HowtoBuy from "./pages/HowtoBuy";
import Roadmap from "./pages/Roadmap";
// import Tokenomics from "./pages/Tokenomics";
// import Presalestages from "./pages/Presalestages";
import FAQs from "./pages/FAQs";
import ContactUs from "./pages/ContactUs";
import Footer from "./pages/Footer";


function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Header />
      <HeroSection />
      <Partners />
      <AboutUs />
      {/* <HowtoBuy /> */}
      <Roadmap />
      {/* <Tokenomics /> */}
      {/* <Presalestages /> */}
      <FAQs />
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
