import React from 'react'

function ContactUs() {
    return (
        <section className="share_area" id="share_presale">
            <div className="container">
                <div className="share_box">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="share_text">
                                <h2>Ready to Join the Bugs Bunny ($BUGS) Adventure?</h2>
                                <p>
                                    Don't miss your chance to be part of an exciting journey in the
                                    world of cryptocurrencies. Bugs Bunny ($BUGS) is more than just a
                                    token; it's a vibrant community. Whether you're a seasoned crypto
                                    enthusiast or just beginning to explore this space, Bugs Bunny
                                    ($BUGS) welcomes you with open arms.
                                </p>
                            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x402b2d2fc1634e2faecf3287541a160617bf4506" target="_blank">  <button className="btn_1">SEE THE CHART</button> </a>  
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="share_img">
                                <img src="assets/images/share/penguin.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ContactUs