import React from 'react'

function Roadmap() {
    return (
        <section className="roadmap_area section_padding" id="roadmap">
            <div className="container">
                <div className="section_title step_title">
                    <h4>Leading the Way to a Bright Future</h4>
                    <h2>ROADMAP</h2>
                    <p>
                        At Bugs Bunny ($BUGS), we believe in transparency and empowering our
                        community with a clear understanding of our tokenomics. Our tokenomics
                        model has been carefully designed to create a sustainable and rewarding
                        ecosystem for all $BUGS holders.
                    </p>
                </div>
                <div className="roadmapd">
                    <img src="assets/images/roadmap/Roadmap.png" alt="" />
                    <div className="roadmap_single first_roadmap">
                        <h4>Q3 2023</h4>
                        <ul>
                            <li>
                                <p>Whitepaper</p>
                            </li>
                            <li>
                                <p>Token Development</p>
                            </li>
                            <li>
                                <p>Tokenomics & Token Metrics</p>
                            </li>
                            <li>
                                <p>Market Research</p>
                            </li>
                        </ul>
                    </div>
                    <div className="roadmap_single second_roadmap">
                        <h4>Q4 2023</h4>
                        <ul>
                            <li>
                                <p>Site Launch</p>
                            </li>
                            <li>
                                <p>Presale Start</p>
                            </li>
                            <li>
                                <p>Token Launch</p>
                            </li>
                            <li>
                                <p>Social Media Accounts Launch</p>
                            </li>
                            <li>
                                <p>Social Media Campaign Start</p>
                            </li>
                            <li>
                                <p>Marketing Campaign Start</p>
                            </li>
                            <li>
                                <p>DEX Listing</p>
                            </li>
                            <li>
                                <p>CMC Listing</p>
                            </li>
                        </ul>
                    </div>
                    <div className="roadmap_single third_roadmap">
                        <h4>Q1 2024</h4>
                        <ul>
                            <li>
                                <p>Partnerships</p>
                            </li>
                            <li>
                                <p>CEX Listing</p>
                            </li>
                            <li>
                                <p>Community Events</p>
                            </li>
                            <li>
                                <p>More to come...</p>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="roadmap_single last_roadmap">
                        <h4>Q2 2024</h4>
                        <ul>
                            <li>
                                <p>Ecosystem Construction</p>
                            </li>
                            <li>
                                <p>Charity Events</p>
                            </li>
                            <li>
                                <p>More coming soon!</p>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <img src="assets/images/roadmap/pngwing 4.png" alt="" />
        </section>

    )
}

export default Roadmap