import React from 'react'

function AboutUs() {
    return (
        <section className="adventure_area" id="about">
            <div className="container">
                <div className="row gx-4">
                    <div className="col-lg-6">
                        <div className="adevnture_left">
                            <h4>start your adventure</h4>
                            <h2>What is Bugs Bunny ($BUGS)?</h2>
                            <p>
                                The Bugs Bunny Token ($BUGS) was born out of a shared vision among a
                                group of crypto enthusiasts who are drawn together by their love of
                                the childhood cartoon show „Looney Tunes“ and crypto. With this new
                                cryptocurrency, the main character of the popular childhood series
                                comes back to life and brings all his joy and nostalgia under the
                                stressful life of a crypto investor. To that end, the currency
                                manages to achieve price growth due to its large community and
                                appeal, putting its followers financially in a great direction.
                            </p>
                            <a href="https://youtu.be/rfu7vnbzaFU" target="_blank" className="btn_2">
                                SEE VIDEO
                            </a>
                            <div className="adventure_img">
                                <img src="assets/images/adventure/pngwing.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="adventure_right">
                            <div className="adventure_single">
                                <h4>Creative Initiatives</h4>
                                <p>
                                    The team plans to engage as much as possible in new ideas and
                                    implementations to expand the project in all directions. By
                                    holding $BUGS you embark on an exciting and adventurous journey
                                    with goals and achievements. Together we will conquer the Memecoin
                                    World!
                                </p>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </div>
                            <div className="adventure_single">
                                <h4>Price and Market Data</h4>
                                <p>
                                    The token is launched on Uniswap V3 and all the data can be viewed on Coinmarketcap or Dextools.
                                    It is never too late to enter $BUGS rocket and join our mission to the MOON! Make sure to check the $BUGS chart daily and check our socials as well
                                </p>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </div>
                            <div className="adventure_single">
                                <h4>Community-Powered</h4>
                                <p>
                                    $BUGS is all about the community! This makes YOU the main part of
                                    this project and part of a big crypto community and Bugs Bunny
                                    fanbase. Due to the constant growth of the currency, millions of
                                    people will become aware of $BUGS, which will help achieve the
                                    goal of bringing Bugs Bunny to the top in the crypto world!
                                </p>
                                <img
                                    className="up_left"
                                    src="assets/images/adventure/Up-left.png"
                                    alt=""
                                />
                                <img
                                    className="up_right"
                                    src="assets/images/adventure/Up-right.png"
                                    alt=""
                                />
                                <img
                                    className="down_left"
                                    src="assets/images/adventure/Down-left.png"
                                    alt=""
                                />
                                <img
                                    className="down_right"
                                    src="assets/images/adventure/Down-right.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default AboutUs